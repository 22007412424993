import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import GUI from 'lil-gui'
import gsap from 'gsap/gsap-core'
import { addObjectClickListener } from './addlistener.js'


// Debug
const gui = new GUI()
gui.hide()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scenes
const scene = new THREE.Scene()
const raycaster = new THREE.Raycaster()
//Update all materials
// const updateAllMaterials = () =>
// {
//     scene.traverse((child) =>
//     {
//         if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
//         {
//             child.material.envMap = environmentMap
//             child.material.envMapIntensity = debugObject.envMapIntensity
//             child.material.needsUpdate = true
//             child.castShadow = true
//             child.receiveShadow = true
//         }
//     })
// }



//LOADERS
let loadedObject = null
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 , delay: 1 })
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''  
            const loadedObject = findObjectByName(scene, "card_image")
            console.log(loadedObject)
            
            addObjectClickListener(camera,scene,raycaster,loadedObject)
        }, 500)
        console.log('loaded')
        //updateAllMaterials()
    },
    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)


const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
const textureLoader = new THREE.TextureLoader(loadingManager)
const particleTexture = textureLoader.load('/textures/particle_circle.png')
const matcapTexture = textureLoader.load('/textures/matcaps/8.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace
const environmentMap = cubeTextureLoader.load([
    '/env/0/px.png',
    '/env/0/nx.png',
    '/env/0/py.png',
    '/env/0/ny.png',
    '/env/0/pz.png',
    '/env/0/nz.png'
])
environmentMap.intensity = 0.5
scene.environment = environmentMap

gltfLoader.load(
    '/models/vs_site_card_cb.glb',
    (gltf) =>
    {
        while(gltf.scene.children.length)
        {
            scene.add(gltf.scene.children[0])
        }
        console.log('MODEL LOAD - SUCCESS')
        // updateAllMaterials()    
    },
    (progress) =>
    {
        //console.log('PROGRESS')
        //console.log(progress)
    },
    (error) =>
    {
        console.log('ERROR')
        //console.log(error)
    }
)

//PARTICLES
const particlesGeometry = new THREE.BufferGeometry(loadingManager)
const particlesMaterial = new THREE.PointsMaterial()
particlesMaterial.size = 0.4
particlesMaterial.sizeAttenuation = true
particlesMaterial.color = new THREE.Color(0x0000ff)
particlesMaterial.transparent = true
particlesMaterial.opacity = 0.08
particlesMaterial.alphaMap = particleTexture
//particlesMaterial.alphaTest = 0.001
particlesMaterial.depthTest = false
//particlesMaterial.depthWrite = false
particlesMaterial.blending = THREE.AdditiveBlending
particlesMaterial.vertexColors = true
const count = 2000

const positions = new Float32Array(count * 3) // Particle positions
const colors = new Float32Array(count * 3)

for(let i = 0; i < count * 3; i++) // Multiply by 3 for same reason
{
    positions[i] = (Math.random() - 0.5) * 10 // Math.random() - 0.5 to have a random value between -0.5 and +0.5
    colors[i] = Math.random()
}
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
particlesMaterial.map = particleTexture
scene.add(particles)

//SIZE
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.01, 10000)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 0
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enablePan = false
controls.minAzimuthAngle = -( Math.PI * 0.25)
controls.maxAzimuthAngle = Math.PI * 0.25
controls.minPolarAngle = -( Math.PI * 0.5)
controls.maxPolarAngle = Math.PI * 0.5
camera.position.set( 0, 1, 5 );
controls.target.set(0,0.5,0)
controls.rotateSpeed = 0.1
controls.minZoom = 2;
controls.maxZoom = 8
controls.update();


const timeLine = gsap.timeline()
timeLine.fromTo(camera.position,
    { y: 2, z: 11 },
    { y: 0.75, z: 4.2, duration: 10, ease: "power1.out" }
)

//LIGHTING
const spotLight = new THREE.SpotLight( 0xffffff)
spotLight.position.set(-4,1,3)
spotLight.intensity = 18
spotLight.angle = Math.PI/4
spotLight.penumbra = 0.7
spotLight.castShadow = true
spotLight.distance = 500
scene.add(spotLight.target)
spotLight.target.position.set(0,1,-2)

const light = new THREE.AmbientLight(0xffffff, 0.2);
scene.add(light)

scene.add(spotLight)
// const spotlightHelper = new THREE.SpotLightHelper(spotLight)
// scene.add(spotlightHelper)

//OVERLAY
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

//RENDERER
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//Animate
const clock = new THREE.Clock()

//OBJECT FINDER
function findObjectByName(scene, objectName) {
    function traverse(node) {
        if (node.name === objectName) {
            return node;
        }
        for (const child of node.children) {
            const result = traverse(child);
            if (result) {
                return result;
            }
        }
        return null;
    }

    return traverse(scene);
}




const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = clock.getDelta()
    particles.rotation.y = elapsedTime * 0.02

    // Update controls
    controls.update()

    //Cast a ray

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()